import store from "../analytics/store";
import AppContainer from "../components/container";
import { track_sales_screen } from "../analytics/events";
import Header from "../components/header";
import "../App.css";

function Sales(props) {
  store.getState().track(track_sales_screen);
  return (
    <AppContainer>
      <Header />
      <div className="dual-content">
        <div className="content-left-small"></div>
        <div className="content-right-large"></div>
      </div>
    </AppContainer>
  );
}

export default Sales;
