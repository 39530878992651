import store from "../analytics/store";
import AppContainer from "../components/container";
import { track_contact_screen } from "../analytics/events";
import Header from "../components/header";
import "../App.css";

function Contact(props) {
  store.getState().track(track_contact_screen);
  return (
    <AppContainer>
      <Header />
      <div className="dual-content">
        <div className="content-left-small">
          <h1>Contact Us</h1>
          <div className="contact-info">
            <div className="contact-item">
              <div className="contact-label">Phone:</div>
              <div className="contact-detail">
                <a href="tel:+27118351324">+27 11 835 1324</a>
              </div>
            </div>
            <div className="contact-item">
              <div className="contact-label">Fax:</div>
              <div className="contact-detail">
                <a href="tel:+27118351325">+27 11 835 1325</a>
              </div>
            </div>
            <div className="contact-item">
              <div className="contact-label">Physical address</div>
              <div className="contact-detail">
                15 Simmonds Street, Johannesburg
              </div>
            </div>
          </div>
          {/* <div className="contact-map">
            <iframe
              title="GMap"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3579.580972909479!2d28.03988210207742!3d-26.21030593892127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e950ebd2d5529ab%3A0x98d9b3ca9d6eb94f!2s15%20Simmonds%20St%20Selby%20Johannesburg!5e0!3m2!1sen!2sza!4v1626110473175!5m2!1sen!2sza"
              width="250"
              height="150"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div> */}
        </div>
        <div className="content-right-large">
          <div className="contact-map">
            <iframe
              title="GMap"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3579.580972909479!2d28.03988210207742!3d-26.21030593892127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e950ebd2d5529ab%3A0x98d9b3ca9d6eb94f!2s15%20Simmonds%20St%20Selby%20Johannesburg!5e0!3m2!1sen!2sza!4v1626110473175!5m2!1sen!2sza"
              width="500"
              height="400"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
          {/* <h1>Send an email</h1>
          <span className="font-thin">
            <i>* We'll send you a copy too</i>
          </span>
          <p>&nbsp;</p>
          <form>
            <div className="form-field">
              <div className="form-label">Your name</div>
              <div className="form-input">
                <input type="text" />
              </div>
            </div>
            <div className="form-field">
              <div className="form-label">Your phone number</div>
              <div className="form-input">
                <input type="text" />
              </div>
            </div>
            <div className="form-field">
              <div className="form-label">Your email address</div>
              <div className="form-input">
                <input type="text" />
              </div>
            </div>
            <div className="form-field">
              <div className="form-label">Message</div>
              <div className="form-input">
                <textarea></textarea>
              </div>
            </div>
            <div className="form-field">
              <div className="form-label">&nbsp;</div>
              <div className="form-input">
                <input type="submit" />
              </div>
            </div>
          </form> */}
        </div>
      </div>
    </AppContainer>
  );
}

export default Contact;
