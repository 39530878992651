import app from "firebase/app";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB3eivqLfS71D0QA38SVPKY9GkF2rL1Cb8",
  authDomain: "superjack-web.firebaseapp.com",
  projectId: "superjack-web",
  storageBucket: "superjack-web.appspot.com",
  messagingSenderId: "138607389911",
  appId: "1:138607389911:web:d3e30af3be739e7a4c9492",
  measurementId: "G-DVRFDN9GQ3",
};

app.initializeApp(firebaseConfig);

export const track = (event) => {
  // console.log(
  //   `TRACKING ${event.eventName} with ${JSON.stringify(event.eventParameters)}`
  // );
  app.analytics().logEvent(event.eventName, event.eventParameters);
};

export default track;
