import React from "react";

class Header extends React.Component {
  render() {
    return (
      <div className="header">
        <div className="logo">
          <h1>
            <a href="/">Super Jack</a>
          </h1>
          <h3 className="font-thin">
            Repairs to all makes of garage equipment
          </h3>
        </div>
        <div className="navigation">
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            {/* <li>
              <a href="/sales">Sales</a>
            </li>
            <li>
              <a href="/repairs">Repairs</a>
            </li>
            <li>
              <a href="/quotations">Quotations</a>
            </li> */}
            {/* <li>
              <a href="/contact">Contact</a>
            </li> */}
          </ul>
        </div>
      </div>
    );
  }
}

export default Header;
