import React from "react";
import { Switch, Route } from "react-router-dom";

import NotFound from "../screens/notFound";
// import Home from "../screens/home";
import Sales from "../screens/sales";
import Repairs from "../screens/repairs";
import Quotations from "../screens/quotations";
import Contact from "../screens/contact";

const Main = () => {
  return (
    <Switch>
      {" "}
      {/* The Switch decides which component to show based on the current URL.*/}
      {/* <Route exact path="/" component={Home} id={"HELLO"}></Route> */}
      <Route exact path="/" component={Contact} id={"HELLO"}></Route>
      <Route exact path="/sales" component={Sales}></Route>
      <Route exact path="/repairs" component={Repairs}></Route>
      <Route exact path="/quotations" component={Quotations}></Route>
      <Route exact path="/contact" component={Contact}></Route>
      <Route component={NotFound} />
    </Switch>
  );
};

export default Main;
