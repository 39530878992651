import React from "react";
import Footer from "./footer";

class AppContainer extends React.Component {
  render() {
    return (
      <div className="city">
        <div className="App">
          {this.props.children}
          <Footer />
        </div>
      </div>
    );
  }
}

export default AppContainer;
