import track from "./tracker";

export const initialState = {
  track: track ? track : undefined,
  eventCounter: 0,
};

export const trackerReducer = function (state = initialState, action) {
  switch (action.type) {
    case "track":
      return { ...state, eventCounter: state.eventCounter + 1 };
    default:
      return state;
  }
};
