export const track_home_screen = {
  eventName: "page_view",
  eventParameters: {
    page_title: "home",
  },
};

export const track_sales_screen = {
  eventName: "page_view",
  eventParameters: {
    page_title: "sales",
  },
};

export const track_repairs_screen = {
  eventName: "page_view",
  eventParameters: {
    page_title: "repairs",
  },
};

export const track_quotations_screen = {
  eventName: "page_view",
  eventParameters: {
    page_title: "quotations",
  },
};

export const track_contact_screen = {
  eventName: "page_view",
  eventParameters: {
    page_title: "contact",
  },
};
